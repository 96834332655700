<template>
  <div class="title">
    <router-link :to="{name:'Home'}">首页</router-link>
    <router-link v-for="(item,index) in linkList" :to="item.link?{name:`${item.link}`}:''" :key="index">&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;{{ item.name }}</router-link>
  </div>
</template>

<script>
export default {
  name: "breadcrumb",
  props: {
    linkList : Array
  },

}
</script>

<style lang='less' scoped>
  .title{
    width: 1200px;
    height: 60px;
    display: flex;
    align-items: center;
    a {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      .Breadcrumb {
        font-size: 12px;
      }
    }
    a:last-of-type{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #434ED6;
    }
  }
</style>
