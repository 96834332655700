<template>
  <el-form-item :prop="prop" class="formItem" :style="borderBottom?'border-bottom:1px solid #ebebeb':''">
    <div class="label">
      <span></span>
      {{ label }}
      <div class="question">
        <slot name="question"></slot>
      </div>
    </div>
    <slot name="content"></slot>
    <div class="tip" v-if="$slots.tip">
      <slot name="tip"></slot>
    </div>
  </el-form-item>
</template>

<script>
export default {
  name: "formItem",
  props:{
    prop:{
      type:String,
      default:''
    },
    label:{
      type:String,
      default:''
    },
    borderBottom:{
      type:Boolean,
      default:false
    },
  }
}
</script>

<style lang="less" scoped>
.formItem{
  margin: 0 63px 0 23px;
  padding: 30px 0 ;
  .label{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #000000;
    span{
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #000000;
      margin: 0 11px 0 0;
    }

  }
  .tip{
    margin: 10px 0 0;
    width: max-content;
    padding: 7px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff0f0;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #5b5b5b;
    line-height: 16px;
  }

}

</style>
