<template>
  <div class="accountSell" v-loading.fullscreen.lock="uploadLoading"
       element-loading-text="文件上传中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <breadcrumb :link-list="breadcrumbList"></breadcrumb>
    <div class="header">
      <div class="headTitle">
        <img class="headIcon" src="@/assets/image/AccountDeal/gameSelectHead/sellIcon.png">
        <div class="headText">官网寄售</div>
        <img class="headTip" src="@/assets/image/AccountDeal/gameSelectHead/sellTip.png">
      </div>
      <div class="headBack" v-if="step!==0 && step!==3" @click="backBtn">返回</div>
      <router-link :to="{name:'Home'}" class="headBack" v-else>返回首页</router-link>
    </div>
    <div class="step0" v-if="step===0">
      <gameList :gameClick="gameClick" :product_id="product_id"></gameList>
    </div>
    <div class="step1" v-if="step===1">
      <div class="head">
        <div class="title"><span></span>当前选择游戏为</div>
        <img class="gameImg" :src="selectGame.game_image">

        <div class="gameName">{{ selectGame.game_name }}</div>
        <div class="btn" @click="backBtn">重选</div>
      </div>
      <div class="content1_content">
        <div class="C1C_cardListBtn leftBtn" @click="roll(2)"><i class="el-icon-arrow-left"></i></div>
        <div class="C1C_cardList" ref="nav">
          <!-- 选中游戏名称后显示商品类型 -->
          <div ref="C1C_card" class="C1C_card" v-if="selectGame.game_service.length > 1">
            <div class="C1C_card_title">商品类型</div>
            <el-input class="C1C_card_search" v-model="search1" placeholder="输入关键字">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <div class="C1C_card_list">
              <div class="C1C_card_item" :class="productType===item.name?'C1C_card_itemSelect':''"
                  @click="ProductTypeClick(item)"
                  v-for="(item,index) in searchList(search1,selectGame.game_service)" :key="index">
                {{ item.name }}
                <span>
                      <img v-show="productType===item.name" src="@/assets/image/AccountDeal/pointerArrow.png"
                          alt="">
                    </span>
              </div>
            </div>
          </div>
          <div ref="C1C_card" class="C1C_card" v-for="(item,index) in c1Item" :key="index" v-show="item.show && item.entries.length>0">
            <div v-if="item.entries.length>0">
              <div class="C1C_card_title">{{ item.cat_name }}</div>
              <el-input class="C1C_card_search" v-model="item.search" @input="(val)=>{cardSearchInput(val,item)}"
                        placeholder="输入关键字">
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input>
              <div class="C1C_card_list">
                <div class="C1C_card_item" :class="c1Form[item.cat_id] === items.id?'C1C_card_itemSelect':''"
                     @click="c1EntriesClick(item,items,index)"
                     v-for="(items,indexs) in searchList(item.search,item.entries)" :key="indexs">
                    <span>
                      {{ items.name }}
                      <el-popover

                        placement="right"
                        width="150"
                        trigger="hover"
                        content="如何查询是否可二次实名"
                        style="margin: 0 0 0 10px">
                        <a slot="reference" id="smqk" v-if="item.cat_name == '实名情况'"
                           target="https://images.pxb7.com/images/upload/image/20211214/1639469291703390.png"
                           @click.stop
                           href="https://images.pxb7.com/images/upload/image/20211214/1639469291703390.png"
                        >
                          <img src="@/assets/image/AccountDeal/question.png" alt="">
                        </a>
                      </el-popover>
                    </span>
                  <span>
                      <img v-show="c1Form[item.cat_id] === items.id" src="@/assets/image/AccountDeal/pointerArrow.png" alt="">
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div ref="C1C_card" class="C1C_card" v-for="(item,index) in c1AttrItem" :key="'attrItem'+index"
              v-show="item.show">
            <div class="C1C_card_title">{{ item.cat_name }}</div>
            <el-input class="C1C_card_search" v-model="item.search" @input="(val)=>{cardSearchInput(val,item)}"
                placeholder="输入关键字">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <div class="C1C_card_list">
              <div class="C1C_card_item" :class="c1Form[item.cat_id] === items.id?'C1C_card_itemSelect':''"
                  @click="c1EntriesClick(item,items,index)"
                  v-for="(items,indexs) in searchList(item.search,item.entries)" :key="indexs">
                {{ items.name }}
                <span>
                      <img v-show="c1Form[item.cat_id] === items.id" src="@/assets/image/AccountDeal/pointerArrow.png"
                          alt="">
                    </span>
              </div>
            </div>
          </div>
        </div>
        <div class="C1C_cardListBtn rightBtn" @click="roll(1)"><i class="el-icon-arrow-right"></i></div>
        <div class="C1C_selected">
          <div class="C1C_selectedTip">
            您的选择：
          </div>
          <div class="C1C_selectedOption">
            <span v-if="selectGame.gameName">{{ selectGame.gameName }}&ensp;<i
                class="el-icon-arrow-right"></i>&ensp;</span>
            <span v-if="productType">{{ productType }}&ensp;<i class="el-icon-arrow-right"></i>&ensp;</span>
            <span v-for="(item,index) in c1FormText" :key="index">{{ item }}&ensp;<i
                class="el-icon-arrow-right" v-if="item!=''"></i>&ensp;</span>
          </div>
          <div class="C1C_selectedBtn">
            <div class="resBtn" @click="c1ResBtn('resetForm')">重选</div>
          </div>
        </div>
      </div>
      <div class="nextBtn" @click="c1Next" v-if="c1NextBtn">下一步 填写信息</div>
    </div>
    <div class="step2" v-if="step===2">
      <formTitle name="当前选择游戏为" :game-image="selectGame.game_image" :form-text="c1FormText"
          :game-name="selectGame.game_name"
          :type-name="productType" selected :btn-click="backBtn"></formTitle>
      <div class="account" v-if="productType == '游戏帐号'">
        <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="40px" class="form">
          <formTitle name="商品基本信息" required></formTitle>
          <div class="card">
            <form-item ref="screenshot_method" prop="screenshot_method" label="截图方式" borderBottom>
              <div class="question" slot="question" @click="ssmQuestion = true">
                <img src="@/assets/image/AccountDeal/tipQuestion.png" alt="">
                截图方式区别
              </div>
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':infoForm.screenshot_method == item.value}"
                    v-for="(item,index) in screenshotList" :key="index"
                    @click="radioClick(item,infoForm,'screenshot_method')">
                  {{ item.label }}
                </div>
              </div>
            </form-item>
            <form-item ref="price" prop="price" label="售价" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="infoForm.price" placeholder="输入商品价格" class="input"
                    autocomplete="new-password"></el-input>
                <div class="tip">元</div>
              </div>
              <div class="tip" slot="tip">
                官网寄售交易 <span class="priceTip">{{ agentMoney }}元</span>，担保中介交易 <span class="priceTip">{{
                  zhongjieMoney
                }}元</span>，按交易类型只收取其一费用。
              </div>
            </form-item>
            <form-item ref="name" prop="name" label="商品标题" borderBottom
                v-if="selectGame.screenshot_way === 2">
              <div class="content" slot="content">
                <el-input v-model="infoForm.name" placeholder="请输入商品标题" class="input nameInput"
                    autocomplete="new-password"></el-input>
              </div>
            </form-item>
            <form-item ref="image" prop="image" :label="selectGameId === 41?'领主资料封面图':'商品封面图'" borderBottom
                v-if="selectGame.screenshot_way === 2">
              <div class="content" slot="content">
                <el-upload
                    action="#"
                    list-type="picture-card"
                    class="upload"
                    ref="upload38"
                    :on-change="addChange38"
                    :show-file-list="false"
                    :auto-upload="false">
                  <img v-if="infoForm.image" :src="infoForm.image" class="upload_image">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </form-item>
            <form-item ref="cdata" prop="cdata" :label="selectGameId === 41?'账号详情':'自主截图'" borderBottom
                v-if="infoForm.screenshot_method == '0'">
              <div class="content" slot="content">
                <el-upload
                    action="#"
                    list-type="picture-card"
                    class="upload"
                    ref="upload"
                    multiple
                    :on-change="addChange"
                    :file-list="fileList"
                    :auto-upload="false">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" top="10vh">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </div>
              <div class="tip" slot="tip" v-if="selectGameId === 41">
                领主资料，成就，英雄，技能收藏，文明传承，兵装，列王古卷，背包截图越详细越能吸引买家哦 (图片过少将会导致审核失败)
              </div>
              <div class="tip" slot="tip" v-else>
                <span v-if="selectGameId === 15">自主截图内容举例：装备、属性、道具、技能、经络、VIP ，</span>截图越详细越能吸引买家哦（图片过少将会导致审核失败）
              </div>
            </form-item>

            <form-item ref="description" prop="description" label="截图备注">
              <div class="content" slot="content">
                <el-input type="textarea" class="textarea" :rows="9" v-model="infoForm.description"
                    placeholder="备注账号亮点，特色。后台自动添加至标题，更有利于快速售出"></el-input>
              </div>
            </form-item>
          </div>
          <formTitle name="账号信息" required></formTitle>
          <div class="card">
            <form-item ref="server" prop="server" label="具体区服" borderBottom
                v-if="selectGameId === 38 || selectGameId === 41 ">
              <div class="content" slot="content">
                <el-input v-model="infoForm.server" class="input" autocomplete="new-password"
                    placeholder="请输入具体区服"></el-input>
                <div class="tip">
                  (例如s1001)
                </div>
              </div>

            </form-item>
            <form-item ref="game_account" prop="game_account" label="游戏账号" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="infoForm.game_account" class="input" autocomplete="new-password"
                    @blur="getProductTransactions(infoForm.game_account)" placeholder="请输入游戏账号"></el-input>
                <div class="tip" v-if="selectGameId === 41">
                  (快速查看账号:头像-设置-切换账号)
                </div>
              </div>
            </form-item>
            <form-item ref="camp_account" prop="camp_account" label="营地账号" borderBottom v-if="selectGameId == '13'">
              <div class="question" slot="question" @click="camp_accountTipShow = true"><img
                  src="@/assets/image/AccountDeal/tipQuestion.png" alt="">如何查询营地账号
              </div>
              <div class="content" slot="content">
                <el-input v-model="infoForm.camp_account" class="input" autocomplete="new-password"
                    placeholder="请输入营地账号"></el-input>
              </div>
              <div class="tip" slot="tip">
                麻烦老板打开营地查看权限<span>【否则将无法上架成功！】</span>
              </div>
            </form-item>
            <form-item ref="nobility_level" prop="nobility_level" label="贵族等级" borderBottom v-if="selectGameId == '13'">
              <div class="content" slot="content">
                <el-input v-model="infoForm.nobility_level" class="input" autocomplete="new-password"
                    placeholder="请输入贵族等级"></el-input>
              </div>
            </form-item>
            <form-item ref="game_pwd" prop="game_pwd" label="登录密码" borderBottom
                v-if="infoForm.screenshot_method !== '0' && selectGame.screenshot_way !== 2 && selectGameId !== 13">
              <a class="question" slot="question"
                  href="https://images.pxb7.com/images/upload/image/20220318/1647614551337265.jpeg"
                  target="https://images.pxb7.com/images/upload/image/20220318/1647614551337265.jpeg"
                  v-if="selectGameId === 26">
                <img src="@/assets/image/AccountDeal/tipQuestion.png" alt="">
                关闭手机验证流程
              </a>
              <div class="content" slot="content">
                <el-input v-model="infoForm.game_pwd" class="input" autocomplete="new-password"
                    placeholder="请输入游戏登录密码"></el-input>
              </div>
              <div class="tip" slot="tip" v-if="selectGameId === 26">
                关闭手机验证提升上架效率，方便快捷出售!
              </div>
            </form-item>
            <form-item ref="game_pwd_confirm" prop="game_pwd_confirm" label="确认登录密码" borderBottom
                v-if="infoForm.screenshot_method !== '0' && selectGame.screenshot_way !== 2 && selectGameId !== 13">
              <div class="content" slot="content">
                <el-input v-model="infoForm.game_pwd_confirm" class="input" autocomplete="new-password"
                    placeholder="请再次输入游戏登录密码"></el-input>
              </div>
            </form-item>
            <form-item ref="game_second_pwd" prop="game_second_pwd" label="财产安全锁(二级密码)" borderBottom
                v-if="selectGameId === 8 && infoForm.screenshot_method !== '0' ">
              <div class="content" slot="content">
                <el-input v-model="infoForm.game_second_pwd" class="input" autocomplete="new-password"
                    placeholder="仅供截图客户截仓库图使用"></el-input>
              </div>
            </form-item>
            <form-item ref="game_second_pwd" prop="game_second_pwd" label="角色登录密码" borderBottom
                v-if="(selectGameId === 10 || selectGameId === 15) && infoForm.screenshot_method !== '0'">
              <div class="content" slot="content">
                <el-input v-model="infoForm.game_second_pwd" class="input" autocomplete="new-password"
                    placeholder="登录角色需要，没有不用填写"></el-input>
              </div>
            </form-item>
            <form-item ref="is_sell_all" prop="is_sell_all" label="是否全出" borderBottom v-if="selectGameId === 8">
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':infoForm.is_sell_all == item.value}"
                    v-for="(item,index) in is_sell_allList" :key="index"
                    @click="radioClick(item,infoForm,'is_sell_all')">
                  {{ item.label }}
                </div>
              </div>
            </form-item>
            <form-item ref="acc_source" prop="acc_source" label="账号来源" borderBottom>
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':infoForm.acc_source == item.value}"
                    v-for="(item,index) in acc_sourceList" :key="index"
                    @click="radioClick(item,infoForm,'acc_source')">
                  {{ item.label }}
                </div>
              </div>
              <div class="tip" slot="tip">
                请老板根据真实信息准确勾选，如交易前客服核对账号来源有误或提供虚假信息，将存在被拉入黑名单并取消交易的风险哦~
              </div>

            </form-item>
            <form-item ref="steam_id" prop="steam_id" label="steamID" v-if="selectGameId === 21">
              <div class="content" slot="content">
                <el-input v-model="infoForm.steam_id" class="input" autocomplete="new-password"
                    placeholder="请输入您的steamID"></el-input>
              </div>
              <div class="tip" slot="tip">
                需要Steam的17位ID来查询仓库拥有的装备道具
                <a class="check_steamID"
                    href="https://images.pxb7.com/images/upload/image/20211202/1638436019435456.png"
                    target="https://images.pxb7.com/images/upload/image/20211202/1638436019435456.png">
                  <i class="el-icon-view"></i>
                </a>
              </div>
            </form-item>
          </div>
          <formTitle name="完善交易选项" required></formTitle>
          <div class="card">
            <form-item ref="phone" prop="phone" label="联系电话" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="infoForm.phone" class="input" autocomplete="new-password"
                    placeholder="请输入联系电话"></el-input>
              </div>
            </form-item>
            <form-item ref="qq" prop="qq" label="联系QQ" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="infoForm.qq" class="input" autocomplete="new-password"
                    placeholder="请输入联系QQ"></el-input>
              </div>
            </form-item>
            <form-item ref="wx" prop="wx" label="联系微信" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="infoForm.wx" class="input" autocomplete="new-password"
                    placeholder="请输入联系微信"></el-input>
              </div>
              <div class="tip" slot="tip">请填写微信所绑定的手机号码</div>

            </form-item>
            <form-item ref="real_name" prop="real_name" label="实名情况" borderBottom v-if="selectGameId =='25'">
              <a class="question" slot="question"
                  href="https://images.pxb7.com/images/upload/image/20211214/1639469291703390.png"
                  target="https://images.pxb7.com/images/upload/image/20211214/1639469291703390.png">
                <img src="@/assets/image/AccountDeal/tipQuestion.png" alt="">
                如何查询二次实名
              </a>
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':infoForm.real_name == item.value}"
                    v-for="(item,index) in real_nameList" :key="index" @click="radioClick(item,infoForm,'real_name')">
                  {{ item.label }}
                </div>
              </div>
            </form-item>
            <form-item ref="is_premium" prop="is_premium" label="是否接受议价" borderBottom>
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':infoForm.is_premium == item.value}"
                    v-for="(item,index) in is_premiumList" :key="index"
                    @click="radioClick(item,infoForm,'is_premium')">
                  {{ item.label }}
                </div>
              </div>
            </form-item>
            <form-item ref="is_sign" prop="is_sign" label="交易保障合同" borderBottom>
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':infoForm.is_sign == item.value}"
                    v-for="(item,index) in is_signList" :key="index" @click="radioClick(item,infoForm,'is_sign')">
                  {{ item.label }}
                </div>
              </div>
              <div class="tip" slot="tip">
                <div v-if="infoForm.is_sign == '1'">
                  签署专业法律合同， 保障自身与买家权益，账号也可以获得“<span>支持包赔</span>”标签，提供商品关注度和曝光量
                </div>
                <div v-else>
                  商品官网权重降低，影响商品关注度和曝光量，不支持全网全渠道推广宣传
                </div>
              </div>
            </form-item>
            <form-item ref="bind_psn" prop="bind_psn" label="是否绑定psn" borderBottom v-if="selectGameId === 26">
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':infoForm.bind_psn == item.value}"
                    v-for="(item,index) in bind_psnList" :key="index" @click="radioClick(item,infoForm,'bind_psn')">
                  {{ item.label }}
                </div>
              </div>
            </form-item>
            <form-item ref="bind_tap" prop="bind_tap" label="是否绑定tap" borderBottom v-if="selectGameId === 26">
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':infoForm.bind_tap == item.value}"
                    v-for="(item,index) in bind_psnList" :key="index" @click="radioClick(item,infoForm,'bind_tap')">
                  {{ item.label }}
                </div>
              </div>
            </form-item>
<!--            <form-item ref="money_income" prop="money_income" label="资金默认转入">
              <div class="radio" slot="content">
                <div class="option optionSelect">
                  我的螃蟹账户
                </div>
              </div>
              <div class="tip" slot="tip">
                <div>
                  平台账号充值提现开发中，<span>目前支持至银行、支付宝、微信放款</span>，财务将会在交易完成后放款到您指定的账户～
                </div>
              </div>
            </form-item>-->
          </div>
          <div class="submit">
            <div class="btn" @click="submit('infoForm')">立即发布</div>
            <div class="tip">注意：发布后请务必及时联系客服，否则可能无法成功上架！<span>【立即联系客服完成上架】</span></div>
            <div class="tip" v-if="selectGameId===28 && infoForm.screenshot_method == '1'">
              温馨提示：选择"官方截图"的老板麻烦关闭将军令，以免美工无法登录游戏截图，待收到上架成功的短信提示后在开启将军令！
            </div>
          </div>
        </el-form>
      </div>
      <div class="equip" v-if="productType == '装备道具'">
        <el-form :model="equipmentForm" :rules="equipmentRules" ref="equipmentForm" label-width="40px" class="form">
          <formTitle name="商品信息" required></formTitle>
          <div class="card">
            <form-item ref="sell_price" prop="sell_price" label="单件商品价格" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="equipmentForm.sell_price" placeholder="请输入单件价格" class="input"
                    autocomplete="new-password"></el-input>
                <div class="tip">元</div>
              </div>
              <div class="tip" slot="tip">
                代理费：{{ agentMoney }}元，中介费：{{ zhongjieMoney }}元（收费标准：代理费<span class="priceTip"
                  v-if="gameInfo.equip_agent_fee != 0">{{
                  gameInfo.equip_agent_fee
                }}%</span>，最低{{
                  gameInfo.equip_agent_fee_min
                }}元，最高{{ gameInfo.equip_agent_fee_max }}元；
                中介费<span v-if="gameInfo.equip_zhongjie_fee != 0" class="priceTip">{{
                  gameInfo.equip_zhongjie_fee
                }}%</span>，最低{{ gameInfo.equip_zhongjie_fee_min }}元，最高{{ gameInfo.equip_zhongjie_fee_max }}元）
              </div>
            </form-item>
            <form-item ref="num" prop="num" label="商品件数" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="equipmentForm.num" placeholder="请输入发布数量" class="input"
                    autocomplete="new-password"></el-input>
                <div class="tip">件</div>
              </div>
            </form-item>
            <form-item ref="name" prop="name" label="商品标题" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="equipmentForm.name" placeholder="请输入装备名称" class="input nameInput"
                    autocomplete="new-password" maxlength="50" show-word-limit></el-input>
              </div>
            </form-item>
            <form-item ref="content" prop="content" label="商品描述">
              <div class="content" slot="content">
                <el-input type="textarea" class="textarea" :rows="9" v-model="equipmentForm.content" maxlength="250"
                    show-word-limit
                    :autosize="{ minRows: 4, maxRows: 6}"
                    placeholder="请输入商品详细描述，切勿填写与本商品无关的广告信息和联系方式等。"></el-input>
              </div>
            </form-item>
          </div>
          <formTitle name="身份信息" required></formTitle>
          <div class="card">
            <form-item ref="game_nickname" prop="game_nickname" label="游戏角色名称" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="equipmentForm.game_nickname" placeholder="请输入角色名称" class="input"
                    autocomplete="new-password"></el-input>
              </div>
              <div class="tip" slot="tip">
                打开游戏，同屏当前打1，右键自己角色名称，选择复制角色名称即可。
              </div>
            </form-item>
            <form-item ref="user_phone" prop="user_phone" label="联系电话" borderBottom>
              <div class="content" slot="content">
                <el-input v-model="equipmentForm.user_phone" placeholder="请输入联系电话" class="input"
                    autocomplete="new-password"></el-input>
              </div>
            </form-item>
            <form-item ref="user_qq" prop="user_qq" label="联系QQ">
              <div class="content" slot="content">
                <el-input v-model="equipmentForm.user_qq" placeholder="请输入联系QQ" class="input"
                    autocomplete="new-password"></el-input>
              </div>
            </form-item>
          </div>
          <formTitle name="完善交易选项" required></formTitle>
          <div class="card">
            <form-item ref="c_data" prop="c_data" label="上传详情图片" borderBottom>
              <div class="content" slot="content">
                <el-upload
                    action="#"
                    list-type="picture-card"
                    multiple
                    class="upload"
                    ref="equipUpload"
                    :on-change="equipAddChange"
                    :file-list="equipfileList"
                    :auto-upload="false">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="equipImageUrlView(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span v-if="!equipDisabled" class="el-upload-list__item-delete" @click="equipImgRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="equipVisible" top="10vh">
                  <img width="100%" :src="equipImageUrl" alt="">
                </el-dialog>
              </div>
              <div class="tip" slot="tip">
                单张图片需小于5M，不能带有角色名、联系方式、广告等违规信息。
              </div>
            </form-item>
            <form-item ref="is_premium" prop="is_premium" label="是否接受议价">
              <div class="radio" slot="content">
                <div class="option" :class="{'optionSelect':equipmentForm.is_premium == item.value}"
                    v-for="(item,index) in is_premiumList" :key="index"
                    @click="radioClick(item,equipmentForm,'is_premium')">
                  {{ item.label }}
                </div>
              </div>
            </form-item>
          </div>
          <div class="submit">
            <div class="btn" @click="c2EquipmentNext('equipmentForm')">立即发布</div>
            <div class="tip">防骗提醒：不要轻易将自己的账号信息泄露给他人，官方客服不会以任何形式向玩家索要您的任何密码、密保等信息，请广大玩家时刻注意保护自己
              的账号信息安全，切勿点击陌生链接！<br/> <span>【立即联系客服完成上架】</span></div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="step3" v-if="step===3">
      <div class="img">
        <img src="@/assets/image/AccountDeal/sellSuccess.png" alt="">
        <div class="c3Img_text">
          <div class="c3_success">恭喜您商品发布成功！</div>
          <div class="c3_ground">
            <div class="c3G_id">商品编号：{{ unique_no }}</div>
            <div class="c3G_text">平台审核并截图上架约<span>30分钟</span>左右，请耐心等待～</div>
          </div>
        </div>
      </div>
      <div class="btn cusSerBtn" @click="c1ResBtn('reset')">
        继续发布
      </div>
      <a class="btn" v-if="selectGame.game_id === 10" href="#" @click="cusSerBtnClicks">
        联系客服
      </a>
      <a class="btn" v-else :href="gameInfo.sell_customer" target="_blank">
        联系客服
      </a>
      <div class="tip">(上架问题请联系在线客服)</div>
    </div>
    <!--  截图方式说明  -->
    <el-dialog
        :visible.sync="ssmQuestion"
        custom-class="ssmQuestion"
        :show-close="false"
        width="444px"
        top="35vh"
        center>
      <div id="ssmQuestion">
        <div class="answer">
          <div class="title">
            <span></span>
            官方截图：
          </div>
          <div class="text">
            1.由截图美工截取账号详情，编辑标题挂售到官方网站（30分钟左右）<br/>
            2.官方截图完成并上架成功后，建议号主及时修改下密码。
          </div>
        </div>
        <div class="answer">
          <div class="title">
            <span></span>
            自主截图：
          </div>
          <div class="text">
            号主只需提供账号，并进行珍贵道具备注，自主上传游戏截图，编辑标题，越详细越好，有助于提高曝光率及时售出
          </div>
        </div>
        <img class="close" src="../../../assets/image/AccountDeal/close.png" alt="" @click="ssmQuestion = false">
      </div>
    </el-dialog>
    <!--  王者荣耀 如何查询营地账号  -->
    <el-dialog
        :visible.sync="camp_accountTipShow"
        custom-class="camp_accountDialog"
        top="10vh"
        :show-close="false"
        center
    >
      <div id="camp_accountDialog">
        <img class="content" src="https://images.pxb7.com/images/upload/image/20220303/1646289484638343.png" alt="">
        <img class="btn" @click="camp_accountTipShow = false" src="@/assets/image/AccountDeal/tipClose.png" alt="">

      </div>
    </el-dialog>
  </div>
</template>
<script>

import breadcrumb from "@/components/breadcrumb";
import gameList from "@/components/gameList/index"
import formTitle from "@/views/AccountDeal/AccountSell/formTitle";
import formItem from "@/views/AccountDeal/AccountSell/formItem";
import {
  apiEquipAdd,
  apiEquipAttr,
  apiEquipCategory,
  apiGameInfo,
  apiGoldAttr,
  apiGoldCategory, apiProductAdd,
  apiProductCategory,
  apiProductPcate, apiProductTransactions, apiUploadEquip, apiUploadPicture, apiuserInfo
} from "@/request/API";

export default {
  name: 'accountSell',
  props: {},
  components: {
    breadcrumb,
    gameList,
    formTitle,
    formItem
  },
  data() {
    var valiGamePwd = (rule, value, callback) => {
      if (value !== this.infoForm.game_pwd) {
        callback(new Error('两次输入的密码不一致!'))
      } else {
        callback()
      }
    }
    var changeC_Data = (rule, value, callback) => {
      if (this.equipfilePath.length === 0) {
        callback(new Error('请上传详情图片！'))
      } else {
        callback()
      }
    }
    var changeCdata = (rule, value, callback) => {
      if (this.filePath.length === 0 && this.infoForm.screenshot_method == '0') {
        callback(new Error('请上传截图！'))
      } else {
        callback()
      }
    }
    var changeCdata38 = (rule, value, callback) => {
      if (this.infoForm.image === '' && this.infoForm.screenshot_method == '0') {
        callback(new Error('请上传截图！'))
      } else {
        callback()
      }
    }
    var changePrice = (rule, value, callback) => {
      this.agentMoney = ((value / 100).toFixed(2) * this.gameInfo.agent_fee).toFixed(2)
      this.zhongjieMoney = ((value / 100).toFixed(2) * this.gameInfo.zhongjie_fee).toFixed(2)
      if (this.agentMoney < this.gameInfo.agent_fee_min) {
        this.agentMoney = this.gameInfo.agent_fee_min
      } else if (this.agentMoney > this.gameInfo.agent_fee_max) {
        this.agentMoney = this.gameInfo.agent_fee_max
      }
      if (this.zhongjieMoney < this.gameInfo.zhongjie_fee_min) {
        this.zhongjieMoney = this.gameInfo.zhongjie_fee_min
      } else if (this.zhongjieMoney > this.gameInfo.zhongjie_fee_max) {
        this.zhongjieMoney = this.gameInfo.zhongjie_fee_max
      }
      callback()
    }
    var equipChangePrice = (rule, value, callback) => {
      this.agentMoney = ((value / 100).toFixed(2) * this.gameInfo.equip_agent_fee).toFixed(2)
      this.zhongjieMoney = ((value / 100).toFixed(2) * this.gameInfo.equip_zhongjie_fee).toFixed(2)
      if (this.agentMoney < this.gameInfo.equip_agent_fee_min) {
        this.agentMoney = this.gameInfo.equip_agent_fee_min
      } else if (this.agentMoney > this.gameInfo.equip_agent_fee_max) {
        this.agentMoney = this.gameInfo.equip_agent_fee_max
      }
      if (this.zhongjieMoney < this.gameInfo.equip_zhongjie_fee_min) {
        this.zhongjieMoney = this.gameInfo.equip_zhongjie_fee_min
      } else if (this.zhongjieMoney > this.gameInfo.equip_zhongjie_fee_max) {
        this.zhongjieMoney = this.gameInfo.equip_zhongjie_fee_max
      }
      callback()
    }
    var checkData = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          callback(new Error('请输入正确的游戏账号!'));
        } else {
          callback();
        }
      }
      callback();
    }
    return {
      product_id: this.$route.query.product_id || '',

      apiUploadPicture: apiUploadPicture,
      // gameList 、 merchantList 、 merchantInfo
      step: 0,
      // 面包屑
      breadcrumbList: [
        {name: '我要卖'},
        {name: '官网寄售'}
      ],
      // 选择的游戏
      selectGame: '',
      //商品类型搜索
      search1: '',
      //选择的商品类型
      productType: '',
      //选中的游戏id
      selectGameId: '',
      c1Item: [],
      c1AttrItem: [],
      c1Form: {},
      c1FormAttr: {},
      c2Item: [],
      c2Form: {},
      c1FormText: {},
      c1FormTextAttr: {},
      c1GameList: [],

      gameInfo: '',

      //手续费
      agentMoney: 0,
      //中介飞
      zhongjieMoney: 0,

      // 站内信选择信息
      stationLetter: {},

      //游戏账号表单
      infoForm: {
        name: '',
        image: '',
        is_sell_all: '',
        acc_source: '',
        server: '',
        game_account: '',
        camp_account: '',
        nobility_level: '',
        game_pwd: '',
        game_pwd_confirm: '',
        game_second_pwd: '',
        phone: '',
        qq: '',
        wx: '',
        is_premium: '1',
        is_sign: '1',
        bind_psn: '',
        bind_tap: '',
        cdata: '',
        screenshot_method: '1',
        description: '',
        price: '',
        money_income: '1',
        steam_id: '',
        real_name: ''
      },
      infoForm1: {
        name: '',
        image: '',
        is_sell_all: '',
        acc_source: '',
        server: '',
        game_account: '',
        camp_account: '',
        nobility_level: '',
        game_pwd: '',
        game_pwd_confirm: '',
        game_second_pwd: '',
        phone: '',
        qq: '',
        wx: '',
        is_premium: '1',
        is_sign: '1',
        bind_psn: '',
        bind_tap: '',
        cdata: '',
        screenshot_method: '1',
        description: '',
        price: '',
        money_income: '1',
        steam_id: '',
        real_name: ''
      },
      //游戏账号表单校验规则
      rules: {
        name: [
          {required: true, message: '请输入商品标题', trigger: 'blur'},
        ],
        image: [
          {validator: changeCdata38}
        ],
        is_sell_all: [
          {required: true, message: '请选择是否全出', trigger: 'change'}
        ],
        acc_source: [
          {required: true, message: '请选择账号来源', trigger: 'change'}
        ],
        server: [
          {required: true, message: '请输入具体区服', trigger: 'blur'},
        ],
        game_account: [
          {required: true, message: '请输入游戏账号', trigger: 'blur'},
          {required: true, validator: checkData, trigger: 'blur'},
        ],
        camp_account: [
          {required: true, message: '请输入营地账号', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{6,11}$/, message: '营地账号格式错误'}
        ],
        nobility_level: [
          {required: true, message: '请输入贵族等级', trigger: 'blur'},
          {pattern: /^([0-9]|10)$/, message: '请输入正确的贵族等级'}
        ],
        game_pwd: [
          {required: true, message: '请输入游戏登录密码', trigger: 'blur'},
        ],
        game_pwd_confirm: [
          {required: true, message: '请再次输入游戏登录密码', trigger: 'blur'},
          {required: true, validator: valiGamePwd, trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
        ],
        qq: [
          {required: true, message: '请输入联系QQ', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{4,9}$/, message: 'QQ账号格式错误'}
        ],
        wx: [
          {required: true, message: '请输入联系微信', trigger: 'blur'},
        ],
        real_name: [
          {required: true, message: '请选择实名情况', trigger: 'change'}
        ],
        steam_id: [
          {required: true, message: '请输入您的steamID', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{16,16}$/, message: 'steamID格式错误'}
        ],
        is_premium: [
          {required: true, message: '请选择是否接受议价', trigger: 'change'}
        ],
        is_sign: [
          {required: true, message: '请选择交易保障合同', trigger: 'change'}
        ],
        bind_psn: [
          {required: true, message: '请选择是否绑定psn', trigger: 'change'}
        ],
        bind_tap: [
          {required: true, message: '请选择是否绑定tap', trigger: 'change'}
        ],
        screenshot_method: [
          {required: true, message: '请选择截图方式', trigger: 'change'}
        ],
        cdata: [
          {validator: changeCdata}
        ],
        price: [
          {required: true, message: '请输入商品价格', trigger: 'blur'},
          {pattern: /(^[1-9]\d*$)/, message: '请输入正确的商品价格'},
          {validator: changePrice, trigger: 'change'}
        ],
        money_income: [
          {required: true, message: '请选择资金转入方式', trigger: 'change'}
        ],

      },
      //游戏装备表单
      equipmentForm: {
        name: '',
        sell_price: '',
        num: '',
        content: '',
        game_nickname: '',
        user_phone: '',
        user_qq: '',
        image: '',
        c_data: '',
        // is_proof: '1', //是否有价值证明  0无  1有
        // proof: '',
        is_premium: '1',
      },
      //游戏装备表单校验规则
      equipmentRules: {
        name: [
          {required: true, message: '请输入装备名称', trigger: 'blur'},
        ],
        sell_price: [
          {required: true, message: '请输入单件价格', trigger: 'blur'},
          {pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/, message: '请输入正确的单件价格'},
          {validator: equipChangePrice, trigger: 'change'}
        ],
        num: [
          {required: true, message: '请输入发布数量', trigger: 'blur'},
          {pattern: /^[1-9]\d*$/, message: '请输入正确的数量'},
        ],
        content: [
          {required: true, message: '请输入商品描述', trigger: 'blur'},
        ],
        game_nickname: [
          {required: true, message: '请输入游戏角色名', trigger: 'blur'},
        ],
        user_phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
        ],
        user_qq: [
          {required: true, message: '请输入QQ号', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{4,9}$/, message: 'QQ账号格式错误'}
        ],
        c_data: [
          {validator: changeC_Data},
          // {required: true, message: '请上传详情图片'},

        ],
        // is_proof: [
        //   {required: true, message: '请选择是否有价值证明', trigger: 'change'}
        // ],
        is_premium: [
          {required: true, message: '请选择是否接受议价', trigger: 'change'}
        ],
      },

      //截图方式
      screenshotList: [
        {value: '1', label: '官方截图'},
        {value: '0', label: '自主截图'},
      ],
      //是否全出
      is_sell_allList: [
        {value: '0', label: '全出'},
        {value: '1', label: '只出游戏（给安全中心）'},
        {value: '2', label: '只出游戏（不给安全中心）'},
      ],
      //账号来源
      acc_sourceList: [
        {value: '0', label: '自己注册'},
        {value: '1', label: '平台购买'},
        {value: '2', label: '其他平台购买（入手已满6个月）'},
        {value: '3', label: '其他平台购买（入手未满6个月）'},
      ],
      //实名情况
      real_nameList: [
        {value: '1', label: '可二次实名'},
        {value: '0', label: '不可二次实名'},
      ],
      //是否接受议价
      is_premiumList: [
        {value: '1', label: '接受'},
        {value: '0', label: '不接受'},
      ],
      //交易保障合同
      is_signList: [
        {value: '1', label: '签署'},
        {value: '0', label: '不签署'},
      ],
      //是否绑定psn
      bind_psnList: [
        {value: '1', label: '是'},
        {value: '0', label: '否'},
      ],
      //是否绑定tap
      bind_tapList: [
        {value: '1', label: '是'},
        {value: '0', label: '否'},
      ],
      //截图方式提示弹窗
      ssmQuestion: false,
      //游戏账号 自主截图
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      //游戏账号 自主截图文件列表
      fileList: [],
      //游戏账号 自主截图文件path列表
      filePath: [],
      //王者荣耀 如何查询营地账号
      camp_accountTipShow: false,
      //提交按钮
      submitBtn: true,
      //发布成功之后返回的编号
      unique_no: '',
      //游戏装备详情 图片上传
      equipImageUrl: '',
      equipVisible: false,
      equipDisabled: false,
      //游戏装备详情 图片上传文件列表
      equipfileList: [],
      //游戏装备详情 图片上传文件path列表
      equipfilePath: [],
      //下一步 填写信息按钮显示
      c1NextBtn: false,
      uploadLoading:false,
    }
  },
  methods: {
    cusSerBtnClicks() {
      this.$router.push({
        name: 'CusSerCenter',
        query: {
          game_id: this.selectGame.game_id
        }
      })
    },
    /**
     * 点击游戏事件
     * @param game
     * @param next:true,false 判断是否站内信
     * @param sLetter 接收组件传送的站内信数据
     */
    gameClick(game, next, sLetter) {
      this.selectGame = game
      this.selectGameId = game.game_id
      this.stationLetter = {...sLetter}
      this.step++;
      //如果选择的游戏 商品类型只有一个 默认选上
      if (game.game_service.length === 1 || next) {
        this.ProductTypeClick(game.game_service[0])
      }

    },
    /**
     * 返回按钮
     */
    backBtn() {
      this.c1ResBtn('back')
    },
    /**
     * 步骤一 scoll滚动
     */
    roll(index) {
      let maxScrollLeft = this.$refs.nav.scrollWidth - this.$refs.nav.clientWidth
      if (index == 1) {
        let $this = this.$refs.nav;
        setTimeout(function animation() {
          if ($this.scrollLeft < maxScrollLeft) {
            setTimeout(() => {
              //步进速度
              $this.scrollLeft = $this.scrollLeft + 5;
              //返回顶部
              if ($this.scrollLeft < maxScrollLeft) {
                $this.scrollLeft = $this.scrollLeft + 5;
              }
              animation();
            }, 5);
          }
        }, 10);
      } else {
        let $this = this.$refs.nav;
        //返回顶部动画特效
        setTimeout(function animation() {
          if ($this.scrollLeft > 0) {
            setTimeout(() => {
              //步进速度
              $this.scrollLeft = $this.scrollLeft - 5;
              //返回顶部
              if ($this.scrollLeft > 0) {
                $this.scrollLeft = $this.scrollLeft - 5;
              }
              animation();
            }, 5);
          }
        }, 10);
      }
    },

    /**
     * 步骤一 选择商品类型
     */
    ProductTypeClick(item) {
      this.productType = item.name
      this.c1Item = []
      this.c1AttrItem = []
      this.c1Form = {}
      this.c1FormAttr = {}
      this.c2Item = []
      this.c2Form = {}
      this.c1FormText = {}
      this.c1FormTextAttr = {}
      this.c1GameList = []
      this.getZoneList(this.selectGameId)

      // this.getGameList(item.type)
    },

    /**
     * 步骤一 搜索游戏名称
     * @param indexKey 关键词
     * @param list 数组
     */
    searchList(indexKey, list) {
      if (indexKey) {
        var reg = new RegExp(indexKey, 'ig')
        return list.filter(function (e) {
          //匹配所有字段
          return Object.keys(e).some(function (key) {
            if (typeof e[key] == 'string') {
              return e[key].match(reg);
            }
          })
          this.$forceUpdate()
        })
      }
      return list;
    },

    /**
     * 获取游戏大区列表
     */
    getZoneList(game_id, next) {
      apiGameInfo({
        id: this.selectGameId
      }).then(res => {
        this.gameInfo = res.data;
        // if(next){
        //   let item = this.gameInfo.game_service.find(child=>child.name=='游戏帐号')
        //   this.ProductTypeClick(item)
        // }
      })
      switch (this.productType) {
        case '游戏帐号':
          apiProductCategory({
            game_id: game_id,
            type: 'sale'
          }).then(res => {
            res.data.forEach((item, index) => {
              if (item.unique_id != 'rzhs' && item.unique_id != 'ajg' && item.is_gaoji !== 1 && (item.unique_id != 'ahy')) {
                this.c1Item.push(item)
                this.$set(this.c1Form, item.cat_id, '')
                this.$set(this.c1FormText, item.cat_id, '')
              }

              if (item.is_gaoji === 1) {
                if (item.haveAttr) {
                  this.c2Item.push(item)
                  this.$set(this.c2Form, item.cat_id, '')
                }
              }
            })

            this.c1Item.forEach((item, index) => {
              item.search = ''
              if (index === 0) {
                item.show = true;
                if (this.stationLetter.game_id) {
                  this.getC1ItemFrist(item, index)
                }
              } else {
                item.show = this.stationLetter.game_id ? true : false
              }
              if (item.parent_id == 0) {
                this.getC1ItemFrist(item, index)
              }
            })
          })
          break;
        case 2:
          apiGoldCategory({
            game_id: game_id,
            type: 'sale'
          }).then(res => {
            res.data.forEach((item, index) => {
              if (item.unique_id != 'rzhs' && item.unique_id != 'ajg' && item.is_gaoji !== 1) {
                this.c1Item.push(item)
                this.$set(this.c1Form, item.cat_id, '')
                this.$set(this.c1FormText, item.cat_id, '')
              }
              if (item.is_gaoji === 1) {
                if (item.haveAttr) {
                  this.c2Item.push(item)
                  this.$set(this.c2Form, item.cat_id, '')
                }
              }
            })
            this.c1Item.forEach((item, index) => {
              if (index === 0) {
                item.show = true
              } else {
                item.show = false
              }
            })
          })
          break;
        case '装备道具':
          apiEquipCategory({
            game_id: game_id,
            type: 'sale'
          }).then(res => {
            res.data.forEach((item, index) => {
              if (item.unique_id != 'rzhs' && item.cat_name != '按价格') {
                // if (item.entries.length > 0) {
                this.c1Item.push(item)
                this.$set(this.c1Form, item.cat_id, '')
                this.$set(this.c1FormText, item.cat_id, '')
                // } else {
                //   item.search = ''
                //   this.c1AttrItem.push(item)
                //   this.$set(this.c1Form, item.cat_id, '')
                //   this.$set(this.c1FormText, item.cat_id, '')
                // }
              }
              if (item.is_gaoji === 1) {
                if (item.haveAttr) {
                  this.c2Item.push(item)
                  this.$set(this.c2Form, item.cat_id, '')
                }
              }
            })
            this.c1Item.forEach((item, index) => {
              if (index === 0) {
                item.show = true
              } else {
                item.show = false
              }
            })
          })
          break;
      }
    },
    /**
     * 属性选择搜索值变化
     * @param item card item
     * @param val 变化后的值
     */
    cardSearchInput(val, item) {
      this.$set(item, item.search, val)
      this.$forceUpdate()
    },
    /**
     * 步骤一 选项点击监听
     */
   async c1EntriesClick(item, items, index) {
      let storage
      if (index < this.c1Item.length - 1) {
        storage = this.c1Form[this.c1Item[(index + 1)].cat_id]
      }
      if (item.haveChild) {
        if (items.haveChild) {
          switch (this.productType) {
            case '游戏帐号':
             await apiProductPcate({
                game_id: this.selectGameId,
                pid: items.id,
              }).then(res => {
                //下一步按钮隐藏
                this.c1NextBtn = false
                for (let resKey in res.data.list) {
                  this.c1Item.forEach((c1items, c1indexs) => {
                    if (c1items.cat_id === parseInt(resKey)) {
                      c1items.canShow = true
                      c1items.entries.splice(0, c1items.entries.length)
                      res.data.list[resKey].forEach((keyItem, keyIndex) => {
                        c1items.entries.push(keyItem)
                      })
                      if (this.stationLetter.category) {
                        this.$nextTick(() => {
                          this.getC1ItemFrist(c1items, c1indexs)
                        })
                      }

                    }
                  })
                }
                for (let key in this.c1Form) {
                  for (const keyKey in res.data.list) {
                    if (key == keyKey) {
                      if (storage == '') {
                        this.c1Form[key] = ''
                      } else {
                        this.c1Form[key] = '-1'
                      }
                      this.c1FormText[key] = ''
                    }
                  }
                }
              })
              break;
            case 2:
              await apiGoldAttr({
                game_id: this.selectGameId,
                pid: items.id,
              }).then(res => {
                for (let resKey in res.data.list) {
                  this.c1Item.forEach((c1items, c1indexs) => {
                    if (c1items.cat_id === parseInt(resKey)) {
                      c1items.entries.splice(0, c1items.entries.length)
                      res.data.list[resKey].forEach((keyItem, keyIndex) => {
                        c1items.entries.push(keyItem)
                      })
                    }
                  })
                }
                for (let key in this.c1Form) {
                  for (const keyKey in res.data.list) {
                    if (key == keyKey) {
                      if (storage == '') {
                        this.c1Form[key] = ''
                      } else {
                        this.c1Form[key] = '-1'
                      }
                      this.c1FormText[key] = ''
                    }
                  }
                }
              })
              break;
            case '装备道具':
              await apiEquipAttr({
                game_id: this.selectGameId,
                pid: items.id,
              }).then(res => {
                //下一步按钮隐藏
                this.c1NextBtn = false
                this.c1AttrItem.forEach((c1items, c1indexs) => {
                  if (c1items.entries.length > 0) {
                    c1items.entries = []
                  }
                })
                let c1form = Object.keys(this.c1Form)
                let c1formvalue = Object.values(this.c1Form)
                let c1formText = Object.keys(this.c1FormText)
                let c1formTextvalue = Object.values(this.c1FormText)
                this.c1Form = {}
                this.c1FormAttr = {}
                this.c1FormText = {}
                this.c1FormTextAttr = {}
                c1form.forEach((item, index) => {
                  this.c1Item.forEach((c1item, c1index) => {
                    if (c1item.cat_id == item) {
                      this.$set(this.c1Form, item, c1formvalue[index])
                    }
                  })
                })
                c1formText.forEach((item, index) => {
                  this.c1Item.forEach((c1item, c1index) => {
                    if (c1item.cat_id == item) {
                      this.$set(this.c1FormText, item, c1formTextvalue[index])
                    }
                  })
                })

                for (let resKey in res.data.list) {
                  this.c1Item.forEach((c1items, c1indexs) => {
                    if (c1items.cat_id === parseInt(resKey)) {
                      c1items.canShow = true
                      c1items.entries.splice(0, c1items.entries.length)
                      res.data.list[resKey].forEach((keyItem, keyIndex) => {
                        c1items.entries.push(keyItem)
                      })
                    }
                  })
                }
                for (let key in this.c1Form) {
                  for (const keyKey in res.data.list) {
                    if (key == keyKey) {
                      if (storage == '') {
                        this.c1Form[key] = ''
                      } else {
                        this.c1Form[key] = '-1'
                      }
                      this.c1FormText[key] = ''
                    }
                  }
                }
                this.c1Form = Object.assign(this.c1Form, this.c1FormAttr)
                this.c1FormText = Object.assign(this.c1FormText, this.c1FormTextAttr)
              })
              break;
          }
        } else {
          this.c1AttrItem.forEach((c1items, c1indexs) => {
            c1items.entries = []
          })
          let c1form = Object.keys(this.c1Form)
          let c1formvalue = Object.values(this.c1Form)
          let c1formText = Object.keys(this.c1FormText)
          let c1formTextvalue = Object.values(this.c1FormText)
          this.c1Form = {}
          this.c1FormAttr = {}
          this.c1FormText = {}
          this.c1FormTextAttr = {}
          c1form.forEach((item, index) => {
            this.c1Item.forEach((c1item, c1index) => {
              if (c1item.cat_id == item) {
                this.$set(this.c1Form, item, c1formvalue[index])
              }
            })
          })
          c1formText.forEach((item, index) => {
            this.c1Item.forEach((c1item, c1index) => {
              if (c1item.cat_id == item) {
                this.$set(this.c1FormText, item, c1formTextvalue[index])
              }
            })
          })
          this.c1Item.forEach((c1items, c1indexs) => {
            if (c1items.parent_id === item.cat_id) {
              this.c1Item[c1indexs].canShow = false
              this.c1Item[c1indexs].entries.splice(0, this.c1Item[c1indexs].entries.length)
              this.$set(this.c1Form, c1items.cat_id,-2)
              this.$set(this.c1FormText, c1items.cat_id,'')
            }
          })
        }
      }

      if (index < this.c1Item.length - 1) {
        if (!this.c1Item[(index + 1)].canShow && this.c1Item[(index + 1)].entries.length===0) {
          this.c1Item[(index + 2)].show = true
        }else {
          this.c1Item[(index + 1)].show = true
        }
        this.$forceUpdate()
      }

      if (this.c1Form[item.cat_id] == '' && this.c1Item.length - 1 !== index) {
        this.watchRef()
      }
      this.c1Form[item.cat_id] = items.id
      this.c1FormText[item.cat_id] = items.name
      this.$forceUpdate()
      for (let c1FormKey in this.c1Form) {
        if (this.c1Form[c1FormKey] != '' && this.c1Form[c1FormKey] != 0 && this.c1Form[c1FormKey] != -1) {
          this.c1NextBtn = true
        } else {
          this.c1NextBtn = false
          return
        }
      }

    },
    //选中按大区
    getC1ItemFrist(objList, indexNum) {
      let cat_name = objList.cat_name;
      if (!this.stationLetter.category) return;
      let categoryChild = this.stationLetter.category.find(item => item.cat_name == cat_name);
      if (!categoryChild) {
        this.stationLetter = {}
        return;
      }
      let entries = objList.entries.find(child => {
        return child.name == categoryChild.attr_name[0]
      })
      if (entries) {
        this.c1EntriesClick(objList, entries, indexNum)
      }
    },
    /**
     * 步骤一 ref
     */
    watchRef() {
      if (this.$refs["C1C_card"]) {
        if (this.$refs["C1C_card"].length > 2) {
          let $this = this.$refs.nav;
          let c1cLength = this.$refs["C1C_card"].length
          let maxScrollLeft = this.$refs.nav.scrollWidth - this.$refs.nav.clientWidth
          if (maxScrollLeft < (c1cLength - 2) * 185) {
            maxScrollLeft = maxScrollLeft + 185
          }
          let move = setTimeout(function animation() {
            if ($this.scrollLeft < maxScrollLeft) {
              setTimeout(() => {
                //步进速度
                $this.scrollLeft = $this.scrollLeft + 5;
                //返回顶部
                if ($this.scrollLeft < maxScrollLeft) {
                  $this.scrollLeft = $this.scrollLeft + 5;
                }
                animation();
              }, 5);
            }
          }, 10);
        }
      }

    },
    /**
     * 步骤一 重选
     */
    c1ResBtn(param) {
      this.equipfileList = []
      this.equipfilePath = []
      this.fileList = []
      this.filePath = []
      switch (this.step) {
        case 1:
        case 3:
          this.c1Item = []
          this.c1AttrItem = []
          this.c1Form = {}
          this.c1FormAttr = {}
          this.c2Item = []
          this.c2Form = {}
          this.c1FormText = {}
          this.c1FormTextAttr = {}
          switch (param) {
            case 'back':
              this.step--
              this.selectGameId = ''
              this.c1GameListLabel = ''
              this.productType = ''
              break;
            case 'reset':
              if (this.step === 3) {
                this.step = 0
              } else {
                this.step--
              }
              //如果选择的游戏 商品类型只有一个 默认选上
              if (this.selectGame.game_service.length === 1) {
                this.ProductTypeClick(this.selectGame.game_service[0])
              } else {
                this.productType = ''
              }
              for (let infoFormKey in this.infoForm) {
                this.infoForm[infoFormKey] = this.infoForm1[infoFormKey]
              }
              break;
            case 'resetForm':
              //如果选择的游戏 商品类型只有一个 默认选上
              if (this.selectGame.game_service.length === 1) {
                this.ProductTypeClick(this.selectGame.game_service[0])
              } else {
                this.productType = ''
              }
              this.c1NextBtn = false
              break;
          }
          break;
        case 2:
          this.step--
          for (let infoFormKey in this.infoForm) {
            this.infoForm[infoFormKey] = this.infoForm1[infoFormKey]
          }
          break;
      }
    },

    /**
     * 下一步 填写信息
     */
    c1Next() {

      this.c1Item.forEach((item,index)=>{
        if (item.parent_id !== 0) {
          if (item.entries.length === 0) {
            for (const c1FormKey in this.c1Form) {
              if (c1FormKey == item.cat_id) {
                this.$set(this.c1Form, c1FormKey, -1)
                this.$set(this.c1FormText, c1FormKey, '')
              }
            }
          }
        }
      })

      if (this.$store.state.loginStatus) {
        let flag = true
        for (let c1FormKey in this.c1Form) {
          if (this.c1Form[c1FormKey] === '' || this.c1Form[c1FormKey] === 0 || this.c1Form[c1FormKey] == -1) {
            flag = false
          }
        }
        this.c1Item.forEach((item,index)=>{
          if (!item.canShow) {
            flag = true
          }
        })
        if (flag) {
          if (this.selectGameId === 11 || this.selectGame.screenshot_way === 2) {
            this.infoForm.screenshot_method = '0'
          }
          if (this.step++ >= 2) this.step = 0;
          document.body.scrollTop = document.documentElement.scrollTop = 157;
          apiuserInfo({
            action: 'base'
          }).then(res => {
            this.infoForm.phone = res.data.used_phone ? res.data.used_phone : res.data.telphone
            /**
             * 判断是否是站内信的数据
             */
            if (this.stationLetter.id) {
              this.fileList = []
              let str = this.stationLetter.cdata || ''
              let strA = this.stationLetter.arms_data || ''
              let arr = [...JSON.parse(str), ...JSON.parse(strA)]
              for (var i = 0; i < arr.length; i++) {
                this.fileList.push({
                  url: arr[i]
                })
              }
              if (this.fileList.length > 0) {
                this.infoForm.screenshot_method = '0'
              }
              this.infoForm.qq = this.stationLetter.qq
            } else {
              this.infoForm.qq = res.data.used_qq ? res.data.used_qq : res.data.qq
            }
            this.infoForm.wx = res.data.used_wx

            this.equipmentForm.user_phone = res.data.used_phone ? res.data.used_phone : res.data.telphone
            this.equipmentForm.user_qq = res.data.used_qq ? res.data.used_qq : res.data.qq
            // this.goldForm.user_phone = res.data.telphone


            // this.goldForm.user_qq = res.data.qq
          })
        } else {
          this.$message({
            message: '有未选项',
            center: true,
            type: 'error'
          })
        }
      } else {
        this.$message({
          type: "error",
          message: '尚未登录，请先登录。',
          center: true
        })
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.path,
            game_name: this.game_name,
            game_id: this.game_id,
            id: this.id,
          },
        })
      }

    },
    /**
     * 单选 点击事件
     * @param item 选中项
     * @param form 表单
     * @param formkey 表单属性
     */
    radioClick(item, form, formkey) {
      this.$set(form, formkey, item.value)
    },

    /**
     * 游戏账号 乱世王者 商品封面图上传
     * @param file 文件
     */
    addChange38(file) {
      this.uploadLoading = true
      let data = new FormData()
      data.append('file', file.raw)
      this.apiUploadPicture(data).then(res => {
        this.infoForm.image = res.data.file_path
      }).catch(err => {
        this.$message({
          type: "error",
          center: true,
          message: err.msg + '(5M以内的png或jpg格式的图片)'
        })
      }).finally(()=>{
        this.uploadLoading = false
      })
    },
    /**
     * 游戏账号 自主截图上传
     * @param file 文件
     */
    addChange(file) {
      let data = new FormData()
      this.uploadLoading = true
      data.append('file', file.raw)
      this.apiUploadPicture(data).then(res => {
        let data = new Object()
        data.path = res.data.file_path
        data.uid = file.uid
        this.fileList.push(file)
        this.filePath.push(data)
      }).catch(err => {
        this.$refs.upload.uploadFiles = this.$refs.upload.uploadFiles.filter(val => val.uid !== file.uid)
        this.$message({
          type: "error",
          center: true,
          message: err.msg + '(5M以内的png或jpg格式的图片)'
        })
      }).finally(()=>{
        this.uploadLoading = false
      })
    },
    /**
     * 步骤二 游戏账号 自主截图删除
     */
    handleRemove(file) {
      this.fileList.splice(this.fileList.findIndex(item => item.uid === file.uid), 1)
      this.filePath.splice(this.filePath.findIndex(item => item.uid === file.uid), 1)
    },
    /**
     * 步骤二 游戏账号 自主截图查看
     */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**
     * 获取游戏账号在本平台的交易次数
     * @param account
     */
    getProductTransactions(account) {
      if (this.selectGameId == 15) {
        apiProductTransactions({
          game_id: this.selectGameId,
          game_acc: account
        }).then(res => {
          if (res.data.transactionsNum === 0) {
            this.accSourceDisable = true
            if (this.infoForm.acc_source == 1) {
              this.infoForm.acc_source = ''
            }
          } else {
            this.accSourceDisable = false
          }
        })
      }
    },

    /**
     * 步骤二 游戏账号 发布商品
     * @param formName 表单名称
     */
    submit(formName) {
      if (this.submitBtn) {
        this.$refs[formName].validate((valid, object) => {
          if (valid) {
            let category = {...this.c1Form, ...this.c2Form}
            for (const categoryKey in category) {
              if (category[categoryKey] < 0) {
                category[categoryKey] = 0
              }
            }
            this.submitBtn = false
            this.infoForm.cdata = this.infoForm.cdata + '<p>'
            if (this.infoForm.image != '') {
              this.infoForm.cdata = this.infoForm.cdata + '<img src="' + this.infoForm.image + '">'
            }
            this.filePath.forEach(item => {
              this.infoForm.cdata = this.infoForm.cdata + '<img src="' + item.path + '">'
            })
            this.infoForm.cdata = this.infoForm.cdata + '</p>'
            apiProductAdd({
              name: this.infoForm.name,
              image: this.infoForm.image,
              game_id: this.selectGameId,
              is_sell_all: this.infoForm.is_sell_all,
              acc_source: this.infoForm.acc_source,
              server: this.infoForm.server,
              game_account: this.infoForm.game_account,
              camp_account: this.infoForm.camp_account,
              nobility_level: this.infoForm.nobility_level,
              game_pwd: this.infoForm.game_pwd,
              game_pwd_confirm: this.infoForm.game_pwd_confirm,
              game_second_pwd: this.infoForm.game_second_pwd,
              phone: this.infoForm.phone,
              qq: this.infoForm.qq,
              wx: this.infoForm.wx,
              is_premium: this.infoForm.is_premium,
              is_sign: this.infoForm.is_sign,
              bind_psn: this.infoForm.bind_psn,
              bind_tap: this.infoForm.bind_tap,
              cdata: this.infoForm.cdata,
              screenshot_method: this.infoForm.screenshot_method,
              description: this.infoForm.description,
              price: this.infoForm.price,
              steam_id: this.infoForm.steam_id,
              real_name: this.infoForm.real_name,
              category: JSON.stringify(category),
            }).then(res => {
              if (this.step++ >= 3) this.active = 0;
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              this.$message({
                message: "发布成功",
                center: true,
                type: "success"
              })
              this.submitBtn = true
              this.unique_no = res.data.unique_no
            }).catch(err => {
              this.$message({
                message: err.msg,
                center: true,
                type: "error"
              })
              this.submitBtn = true
            })
          } else {
            this.submitBtn = true
            this.scrollView(object)
            /*
                        setTimeout(() => {
                          var isError = document.getElementsByClassName("is-error");
                          if (isError[0].querySelector('input')) {
                            isError[0].querySelector('input').focus();
                          } else if (isError[0].querySelector('textarea')) {
                            isError[0].querySelector('textarea').focus();
                          }
                        }, 100);
                        console.log('error submit!!');
                        return false;*/
          }
        });
      }
    },

    /**
     * 表单验证失败时 滚动到第一个error位置
     * @param object 表单验证失败的对象
     */
    scrollView(object) {
      const key = Object.keys(object) // key得到的是表单绑定的值
      let dom = this.$refs[key[0]]  // dom是第一项没有通过的值，需要注意的是vue中获取dom元素用的是ref，所以必须在含有表单校验规则的项上绑定ref，与v-model值相同。
      dom.$el.scrollIntoView({ // 该scrollIntoView()方法将调用它的元素滚动到浏览器窗口的可见区域。
        block: 'center',
        behavior: 'smooth',
      })
    },

    /**
     * 步骤二 游戏装备 详情图片删除
     */
    equipImgRemove(file) {
      this.equipfileList.splice(this.equipfileList.findIndex(item => item.uid === file.uid), 1)
      this.equipfilePath.splice(this.equipfilePath.findIndex(item => item.uid === file.uid), 1)
    },
    /**
     * 步骤二 游戏装备 详情图片查看
     */
    equipImageUrlView(file) {
      this.equipImageUrl = file.url;
      this.equipVisible = true;
    },
    /**
     * 步骤二 游戏装备 装备详情图片上传
     * @param file 文件
     */
    equipAddChange(file) {
      let data = new FormData()
      data.append('file', file.raw)
      apiUploadEquip(data).then(res => {
        let data = new Object()
        data.path = res.data.file_path
        data.uid = file.uid
        this.equipfileList.push(file)
        this.equipfilePath.push(data)
      }).catch(err => {
        this.$refs.equipUpload.uploadFiles = this.$refs.equipUpload.uploadFiles.filter(val => val.uid !== file.uid)
        this.$message({
          type: "error",
          center: true,
          message: err.msg + '(5M以内的png或jpg格式的图片)'
        })
      })
    },
    /**
     * 步骤二 游戏装备 发布商品
     * @param formName 表单名称
     */
    c2EquipmentNext(formName) {
      if (this.submitBtn) {
        this.submitBtn = false
        this.$refs[formName].validate((valid, object) => {
          if (valid) {
            // let category = {...this.c1Form, ...this.c2Form}
            this.equipmentForm.c_data = this.equipmentForm.c_data + '<p>'
            this.equipfilePath.forEach((item, index) => {
              if (index === 0) {
                this.equipmentForm.image = item.path
              }
              this.equipmentForm.c_data = this.equipmentForm.c_data + '<img src="' + item.path + '">'
            })
            this.equipmentForm.c_data = this.equipmentForm.c_data + '</p>'
            /*        this.equipmentForm.proof = this.equipmentForm.proof + '<p>'
                    this.provefilePath.forEach((item, index) => {
                      this.equipmentForm.proof = this.equipmentForm.proof + '<img src="' + item.path + '">'
                    })*/
            this.equipmentForm.proof = this.equipmentForm.proof + '</p>'
            let category = this.c1Form
            for (const categoryKey in category) {
              if (category[categoryKey] < 0) {
                category[categoryKey] = 0
              }
            }
            apiEquipAdd({
              game_id: this.selectGameId,
              name: this.equipmentForm.name,
              content: this.equipmentForm.content,
              sell_price: this.equipmentForm.sell_price,
              num: this.equipmentForm.num,
              image: this.equipmentForm.image,
              c_data: this.equipmentForm.c_data,
              game_nickname: this.equipmentForm.game_nickname,
              // is_proof: this.equipmentForm.is_proof,
              // proof: this.equipmentForm.proof,
              user_phone: this.equipmentForm.user_phone,
              user_qq: this.equipmentForm.user_qq,
              category: JSON.stringify(category),
            }).then(res => {
              if (this.step++ >= 3) this.step = 0;
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              this.$message({
                message: "发布成功",
                center: true,
                type: "success"
              })
              this.submitBtn = true
              // this.centerDialogVisible = true
              this.unique_no = res.data.unique_no
            }).catch(err => {
              this.$message({
                message: err.msg,
                center: true,
                type: "error"
              })
              this.submitBtn = true
            })
          } else {
            this.submitBtn = true
            this.scrollView(object)
            console.log('error submit!!');
            return false;
          }
        });
      }
    },

  },
  mounted() {
  },
  watch: {
    step(val) {
      switch (val) {
        case 0:
          this.breadcrumbList = [
            {name: '我要卖'},
            {name: '官网寄售'},
          ]
          break;
        case 1:
          this.breadcrumbList = [
            {name: '我要卖'},
            {name: '官网寄售'},
            {name: `选择游戏-${this.selectGame.game_name}`},
          ]
          break;
        case 2:
          this.breadcrumbList = [
            {name: '我要卖'},
            {name: '官网寄售'},
            {name: `选择游戏-${this.selectGame.game_name}`},
            {name: `商品类型-${this.productType}`},
          ]
          break;
        case 3:
          this.breadcrumbList = [
            {name: '我要卖'},
            {name: '官网寄售'},
            {name: '发布成功'},
          ]
          break;
      }
    },
    c1FormText(val) {
      if ((this.c1Item.length == Object.keys(this.c1FormText).length) && this.stationLetter.game_id && this.c1Item.length) {
        setTimeout(() => {
          this.c1Next();
        }, 400)
      }
    },
    selectGame(val) {
      switch (val.screenshot_way) {
        case 0:
          this.infoForm.screenshot_method = '1'
          //截图方式
          this.screenshotList = [
            {value: '1', label: '官方截图'},
            {value: '0', label: '自主截图'},
          ]
          break;
        case 1:
          this.infoForm.screenshot_method = '1'
          this.screenshotList = [
            {value: '1', label: '官方截图'},
          ]
          break;
        case 2:
          this.infoForm.screenshot_method = '0'
          this.screenshotList = [
            {value: '0', label: '自主截图'},
          ]
          break;
      }
    }
  },
  computed: {}
}
</script>


<!-- 弹框 -->
<style lang="less">
.ssmQuestion {
  border-radius: 10px;

  .el-dialog__header, .el-dialog__body {
    padding: 0 !important;
  }

  #ssmQuestion {
    position: relative;
    padding: 25px 37px 31px 32px;

    .answer {
      margin: 10px 0;

      .title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #000000;

        span {
          display: inline-block;
          width: 6px;
          height: 16px;
          background: #434ED6;
          margin: 0 8px 0 0;
        }
      }

      .text {
        margin: 5px 0 0;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #5a5a5a;
      }
    }

    .close {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -40px;
      left: calc(50% - 15px);
      cursor: pointer;
    }

  }
}

.camp_accountDialog {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-height: 660px;
  background: transparent;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  #camp_accountDialog {
    width: 1157px;

    .content {
      width: 100%;
      object-fit: contain;
    }

    .btn {
      position: absolute;
      top: 15px;
      right: -210px;
      cursor: pointer;
    }
  }

}
</style>
<style lang='less' scoped>
.accountSell {
  width: 1200px;

  .header {
    width: calc(1200px - 88px);
    height: 70px;
    background: linear-gradient(90deg, #163279 0%, rgba(0,8,77,0.8000) 100%);
    border-radius: 10px 10px 0 0;
    padding: 0 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      display: flex;
      flex-direction: row;
      align-items: center;

      .headText {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: CENTER;
        color: #ffffff;
        margin: 0 29px 0 8px;
      }
    }

    .headBack {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .introduce {
    margin: 0 0 18px;

    .introduceHead {
      margin: 18px 0 4px;
    }

    .introduceBody {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .IB_card {
        margin: 3px 0 0;
        width: 640px;
        height: 144px;
        padding: 23px 34px 23px 43px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .card_title {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #ff9407;
          line-height: 20px;

          span {
            display: inline-block;
            width: 4px;
            height: 14px;
            background: #ff9407;
            margin: 0 14px 0 0;
          }
        }

        .card_text {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #5b5b5b;
          line-height: 25px;
        }

        .card_tip {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #747DE9;
          line-height: 16px;
        }
      }

      img {
        cursor: pointer;
      }
    }
  }

  .step1 {
    width: 1160px;
    background: #ffffff;
    padding: 30px 20px 60px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 0 30px;

      .title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #000000;
        margin: 0 32px 0 0;

        span {
          width: 10px;
          height: 20px;
          background: #000000;
          margin: 0 14px 0 0;
          display: inline-block;
        }
      }

      .gameImg {
        width: 50px;
        height: 50px;
        border-radius: 15px;
        margin: 0 21px 0 0;
      }

      .gameName {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #5b5b5b;
        margin: 0 27px 0 0;
      }

      .btn {
        width: 100px;
        height: 30px;
        line-height: 30px;
        background: #434ED6;
        border-radius: 30px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #FFFFFF;
      }
    }

    .content1_content {
      padding: 20px 25px;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.20);
      display: flex;
      flex-direction: column;
      position: relative;

      .C1C_cardList {
        display: flex;
        flex-direction: row;
        //justify-content: space-between;
        overflow: hidden;

        .C1C_card {
          width: 175px;
          padding: 0 5px;
          flex-shrink: 0; /* 用数值来收缩比例 默认值为1 */

          .C1C_card_title {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: center;
            color: #283748;
            margin: 0 0 18px;
          }

          .C1C_card_search {
            height: 40px;
            background: #ffffff;
            //border: 1px solid #dedede;
            border-radius: 5px;

            /deep/ .el-input__inner:focus {
              border-color: #434ED6 !important;
            }
          }

          .C1C_card_list {
            height: 320px;
            background: #ffffff;
            border: 1px solid #dedede;
            overflow: auto;

            .C1C_card_item {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 20px;
              height: 40px;
              line-height: 40px;
              background: #FFFFFF;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Light;
              font-weight: 300;
              text-align: left;
              color: #8f8f8f;
              cursor: pointer;

              span {
                display: flex;
                flex-direction: row;
                align-items: center;
              }

              #smqk {
                display: flex;
              }
            }

            .C1C_card_item:hover {
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #7D83C3 ;
              background: #FAFAFF;
            }

            .C1C_card_itemSelect {
              padding: 0 20px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #7D83C3 ;
              background: #FAFAFF;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              span {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
          }

          ::-webkit-scrollbar {
            width: 4px; /*对垂直流动条有效*/
            height: 10px; /*对水平流动条有效*/
            display: none;
          }

          /*定义滚动条的轨道颜色、内阴影及圆角*/

          ::-webkit-scrollbar-track {
            border-radius: 2px;
          }

          /*定义滑块颜色、内阴影及圆角*/

          ::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: #ffdea3;
          }

          /*定义两端按钮的样式*/

          ::-webkit-scrollbar-button {
            background-color: none;
          }

          /*定义右下角汇合处的样式*/

          ::-webkit-scrollbar-corner {
            background: khaki;
          }

        }

        .C1C_card:hover {
          ::-webkit-scrollbar {
            display: block;
          }
        }

      }


      .C1C_selected {
        margin: 20px 0 0 5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .C1C_selectedTip {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #283748;
        }

        .C1C_selectedOption {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #7D83C3 ;

          span:last-of-type {
            i {
              display: none;
            }
          }
        }

        .C1C_selectedBtn {
          width: 60px;
          height: 30px;
          background: #eef2f4;
          border-radius: 30px;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: center;
          color: #9aa4a8;
          cursor: pointer;
        }
      }

      .C1C_cardListBtn {
        position: absolute;
        cursor: pointer;
        width: 20px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: rgba(40, 55, 72, 0.30);
        color: #FFFFFF;
      }

      .C1C_cardListBtn:hover {
        background: rgba(40, 55, 72, 0.60);
      }

      .leftBtn {
        left: 0px;
        top: 210px;
      }

      .rightBtn {
        right: 0px;
        top: 210px;
      }
    }

    .nextBtn {
      margin: 65px auto 0;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: CENTER;
      color: #FFFFFF;
      width: 300px;
      height: 50px;
      line-height: 50px;
      background: #434ED6;
      border-radius: 30px;
      cursor: pointer;
    }
  }

  .step2 {
    .account, .equip {
      .form {
        .card {
          background: #FFFFFF;
          padding: 0 0 30px;
          // ?
          .question {
            margin: 0 0 0 21px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #ff7979;
            display: flex;
            align-items: center;
            cursor: pointer;
            vertical-align: middle;

            img {
              width: 20px;
              height: 20px;
              margin: 0 2px 0 0;

            }

          }

          //内容插槽
          .content {
            display: flex;
            align-items: center;
            //提示
            .tip {
              margin: 0 0 0 10px;
              font-size: 16px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #5b5b5b;
            }
          }

          //单选框
          .radio {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .option {
              padding: 0 19px;
              min-width: 100px;
              height: 48px;
              background: #f4f4f4;
              border: 1px solid #f4f4f4;
              border-radius: 10px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              text-align: CENTER;
              color: #585858;
              line-height: 48px;
              margin: 0 15px 0 0;
              cursor: pointer;
            }

            .optionSelect {
              background: #FAFAFF;
              color: #747DE9;
              border: 1px solid #747DE9;
            }

            .option:hover {
              animation: grid-contentMove 0.2s linear infinite; // 重点，定义动画
              animation-fill-mode: forwards;
              animation-iteration-count: 1;
              position: relative;
              z-index: 999999;
              @keyframes grid-contentMove {
                0% {
                  transform: translateY(0);
                }
                100% {
                  transform: translateY(-2px);
                }
              }

            }
          }

          //输入框
          .input {
            width: 222px;

            /deep/ .el-input__inner {
              width: 222px;
              background: #f6f6f6;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #000000;
              border: 1px solid #f0f0f0;
            }

            /deep/ .el-input__inner:focus {
              border: 1px solid #747DE9;
            }

            /deep/ .el-input__inner::-webkit-input-placeholder {
              color: #bfbfbf;
            }
          }

          //标题 输入框  特殊的
          .nameInput {
            width: 100% !important;

            /deep/ .el-input__inner {
              width: 100% !important;
            }

            /deep/ .el-input__count-inner {
              background: transparent;
            }
          }

          //上传
          .upload {
            .upload_image {
              width: 100%;
              object-fit: cover;
            }

            /deep/ .el-upload--picture-card:focus, /deep/ .el-upload--picture-card:hover {
              border-color: #434ED6;

              i {
                color: #434ED6;
              }
            }
          }

          //多行文本框
          .textarea {
            /deep/ .el-textarea__inner:focus {
              border-color: #747DE9;
            }
          }

          //提示框
          .tip {
            span {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #ff750c;
            }

            .priceTip {
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #f47676;
            }
          }
        }

        .submit {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #FFFFFF;
          padding: 0 0 124px;

          .btn {
            cursor: pointer;
            width: 300px;
            height: 50px;
            line-height: 50px;
            background: #434ED6;
            border-radius: 30px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: CENTER;
            color: #FFFFFF;

          }

          .tip {
            width: 696px;
            margin: 20px 0 0;
            background: #fff0f0;
            padding: 7px 20px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #5b5b5b;
            text-align: center;

            span {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              margin: 0 0 0 5px;
              color: #ff750c;
            }
          }
        }
      }
    }
  }

  .step3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    padding: 0 0 87px;

    .img {
      width: 1110px;
      padding: 59px 0 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      img {
        width: 197px;
        height: 130px;
        object-fit: cover;
        margin: 0 26px 0 0;
      }

      .c3Img_text {
        .c3_success {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #5b5b5b;
          margin: 0 6px 6px 19px;
        }

        .c3_ground {
          width: 355px;
          background: #ffffff;
          border: 1px solid #e0e9f2;

          .c3G_id {
            padding: 6px 0 7px 19px;
            background: #a0cbf6;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #ffffff;
          }

          .c3G_text {
            padding: 8px 0 10px 19px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Light;
            font-weight: 300;
            color: #8f8f8f;

            span {
              font-weight: 500;
              color: #434ED6;
            }
          }
        }


      }

    }

    .btn {
      width: 298px;
      height: 48px;
      background: #FFFFFF;
      border: 1px solid #000000;
      border-radius: 30px;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }

    .cusSerBtn {
      margin: 0 0 10px 0;
      background: #434ED6;
      border: 1px solid #434ED6;
    }

    .tip {
      margin: 4px 0 0 19px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #434ED6;
    }
  }
}
</style>
