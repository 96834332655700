<template>
  <div class="formTitle">
    <div class="title">
      <span></span>
      {{name}}
      <div class="required" v-if="required">（必填）</div>
    </div>
    <div class="selected" v-if="selected">
      <img class="img" :src="gameImage">
      <div class="content">
        <span>{{ gameName }}&ensp;<i class="el-icon-arrow-right"></i>&ensp;</span>
        <span>{{ typeName }}&ensp;<i class="el-icon-arrow-right"></i>&ensp;</span>
        <span v-for="(item,index) in formTextList" :key="index">{{ item }}&ensp;<i
          class="el-icon-arrow-right" v-if="index < Object.keys(formText).length-1 "></i>&ensp;</span>
      </div>
      <div class="resBtn" @click="resBtn">重选</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "formTitle",
  props:{
    name:{
      type:String,
      default:''
    },
    selected:{
      type:Boolean,
      default:false
    },
    required:{
      type:Boolean,
      default:false
    },
    formText:{
      type: Object,
      default:null
    },
    gameName:{
      type:String,
      default:''
    },
    gameImage:{
      type:String,
      default:''
    },
    typeName:{
      type:String,
      default:''
    },
    btnClick:{
      type:Function,
      default:null
    }
  },
  data(){
    return {
      formTextList:[]
    }
  },
  methods:{
    resBtn(){
      this.btnClick()
    },
    /**
     * 将formText的obj转换成list
     */
    transform(){
      for (let formTextKey in this.formText) {
        this.formTextList.push(this.formText[formTextKey])
      }
    }

  },
  mounted() {
    this.transform()
  }
}
</script>

<style lang="less" scoped>

.formTitle{
  .title{
    display: flex;
    align-items: center;
    height: 63px;
    line-height: 63px;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #000000;
    span{
      display: inline-block;
      margin: 0 14px 0 39px;
      width: 10px;
      height: 20px;
      background: #000000;
    }
    .required{
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #000000;
    }
  }
  .selected {
    height: 150px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;

    .img {
      margin: 0 17px 0 64px;
      width: 60px;
      height: 60px;
      border-radius: 20px;
    }

    .content {
      span {
        font-size: 16px;
        font-family: PingFangSC-Lightr, PingFang SC;
        font-weight: 400;
        color: #434ED6;
        line-height: 30px;
      }

      i {
        color: #5B5B5B;
      }
    }

    .resBtn {
      margin: 0 0 0 50px;
      width: 100px;
      height: 30px;
      background: #434ED6;
      border-radius: 30px;
      line-height: 30px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
  }

}

</style>
